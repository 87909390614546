//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { channelCode, channelTypes } from '@motionelements/core/src/helpers/channelTypes.js';
import { isProductType } from '@motionelements/core/src/services/catalog.service.js';
import { getChannel } from '@/helpers/channels.js';
import { getCurrencyByLang } from '@/helpers/currency.js';

export default {
  name: 'ChannelPage',
  layout: 'channel',
  async asyncData({ params, redirect, store }) {
    const channel = channelTypes.find(({ link }) => link.includes(params.channel));
    if (channel) {
      const channelId = channel.id;
      const channelRouteName = channel.link.split('/').pop();
      store.dispatch('channels/setChannelType', channelId);

      return { channelId, channelRouteName };
    }
    redirect('/');
  },
  watch: {
    userCurrency: { // @toDo @Pavlo why we need to watch userCurrency and resend getPlans
      handler(toCurrencyCode) {
        if (toCurrencyCode) {
          this.$store.dispatch('subscription/getPlans');
        }
      },
      immediate: true,
    },
  },
  async fetch() {
    this.$store.dispatch('user/setCurrencyCode', getCurrencyByLang(this.$i18n.locale));
    return this.$store.dispatch('subscription/getPlans');
  },
  components: {
    HomeProducts: () => import('@/components/home-page/HomeProducts.vue'),
    ChannelMediaTypeCategories: () => import('@/components/channels/ChannelMediaTypeCategories.vue'),
    FreeStockBanner: () => import('@/components/channels/FreeStockBanner.vue'),
    LottieDemo: () => import('@/components/channels/LottieDemo.vue'),
    HowToUseMidjourney: () => import('@/components/channels/HowToUseMidjourney.vue'),
    PageMeta: () => import('@motionelements/core/src/components/ThePageMeta.vue'),
  },
  data() {
    return {
      channelCode,
    };
  },
  computed: {
    ...mapGetters({
      userCurrency: 'user/currencyCode',
    }),
    isProduction() {
      return process.env.VUE_APP_ENV === 'production';
    },
    pageTitle() {
      return _.get(getChannel(this, this.channelId), 'pageTitle') || _.get(getChannel(this, this.channelId), 'heading');
    },
    pageDescription() {
      return _.get(getChannel(this, this.channelId), 'pageDescription');
    },
    // pageOgImage() {
    //   const languageCode = ['en', 'ja', 'ko', 'zh-hant'].includes(this.$i18n.locale) ? this.$i18n.locale : 'en';
    //   return `https://static.moele.me/img/motionelements-og-${languageCode}.220830.jpg`;
    // },
    channelCategories() {
      const defaultCategories = [
        {
          mediaType: this.channelId, type: 'category', heading: this.$t('category.categories'),
        },
      ];
      if (isProductType('video_template', this.channelId)) {
        defaultCategories.push({
          mediaType: this.channelId, type: 'style', heading: this.$t('category.styles'),
        });
      }
      switch (this.channelId) {
        case channelCode.VIDEO:
          return [
            { mediaType: 'animation', type: 'category', heading: this.renderCategoryHeading('animation') },
            { mediaType: 'video_live', type: 'category', heading: this.renderCategoryHeading('video_live') },
            { mediaType: 'video_vr', type: 'category', heading: this.renderCategoryHeading('video_vr') },
          ];
        case channelCode.MUSIC:
          return [
            { mediaType: channelCode.MUSIC, type: 'mood', heading: this.$t('category.moods') },
            {
              mediaType: channelCode.MUSIC, type: 'genre', heading: this.$t('category.genres'), titleOnly: true, hideShowAll: true,
            },
            { mediaType: channelCode.MUSIC, type: 'category', heading: this.$t('category.production_types') },
            {
              mediaType: channelCode.MUSIC, type: 'instrument', heading: this.$t('category.instruments'), titleOnly: true,
            },
          ];
        case channelCode.IMAGE:
          return [
            { mediaType: 'photo', type: 'category', heading: this.renderCategoryHeading('photo') },
            { mediaType: 'vector', type: 'category', heading: this.renderCategoryHeading('vector') },
            { mediaType: 'gif', type: 'category', heading: this.renderCategoryHeading('gif') },
          ];
        case channelCode.IMAGE_PROMPT:
          return [
            {
              mediaType: channelCode.IMAGE_PROMPT, type: 'generator', heading: this.$t('category.ai_generators'), hideShowAll: true,
            },
            {
              mediaType: channelCode.IMAGE_PROMPT, type: 'style', heading: this.$t('category.styles'), hideShowAll: true,
            },
            {
              mediaType: channelCode.IMAGE_PROMPT, type: 'category', heading: this.$t('category.categories'), hideShowAll: true,
            },
          ];
        // case channelCode.GRAPHIC:
        //   return [
        //     { mediaType: 'graphic', type: 'category', heading: this.renderCategoryHeading('graphic_template') },
        //     { mediaType: 'vector', type: 'category', heading: this.renderCategoryHeading('vector') },
        //   ];
        default:
          return defaultCategories;
      }
    },
  },
  methods: {
    renderCategoryHeading(mediaType) {
      return this.$term('mediaType', mediaType).wbr().plural().html();
    },
    isProductType(productType, mediaType) {
      return isProductType(productType, mediaType);
    },
  },
};
