import { render, staticRenderFns } from "./_channel.vue?vue&type=template&id=120da6ae&"
import script from "./_channel.vue?vue&type=script&lang=js&"
export * from "./_channel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeProducts: require('/opt/render/project/src/channels2022/src/components/home-page/HomeProducts.vue').default})
